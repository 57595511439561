@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  body {
    background-color: #14161b;
  }
}
.slick-dots{
  padding-bottom: 20px;
}
.correct .slick-slide > div {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.correct .slick-dots li.slick-active button:before {
  color:orange !important;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F7FD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav-links{
  text-decoration: none;
  letter-spacing: 0.04em;
}
.nav-links:hover{
  color: #ED3327;
}
.nav-links.active {
  color: #ED3327;
}
.custom-tooltip {
  background-color: white !important; /* Adjust the background color */
  color: black !important; /* Adjust the text color */
  border-radius: 20px;
  border: 1px solid lightgrey;
  
}
/* For small screens (sm) */
@media only screen and (max-width: 600px) {
  .carousel-item {
    top: 200px;
  }
}

/* For medium screens (md) */
@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .carousel-item {
    top: 140px;
  }
}

/* For large screens (lg) */
@media only screen and (min-width: 1200px) {
  .carousel-item {
    top: 0;
  }
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .right-images{
  width: 70px;
    height: 70px;
  }

}

/* For medium screens (md) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .right-images{
     width: 100px;
    height: 100px;
  }
}

/* For large screens (lg) */
@media only screen and (min-width: 1200px) {
  .right-images {
    width: 125px;
    height: 125px;
  }
}

.slick-prev,
.slick-next {
  display: none !important;
}



.Pods {
  padding-left: 30px; /* Adjust the padding value to give space for the icon */
  max-width: 440px;
  border: 1px solid lightgrey;
  border-radius: 80px;
  position: relative;
  padding: 10px 0px 10px 30px;
  background-image: url('assets/Search.svg'); /* Replace with the correct path */
  background-size: 20px; /* Adjust the size of the icon */
  background-repeat: no-repeat;
  background-position: 5px center; /* Adjust the position of the icon */
}

@media (min-width: 350px) {
  .Pods {
    width: 280px; 
  }
}

@media (min-width: 375px) {
  .Pods {
    width: 305px; 
}
}

@media (min-width: 425px) {
  .Pods {
    width: 355px;
  }
}
@media (min-width: 768px) {
  .Pods {
    width: 455px;
  }
}

/* SearchField.css */
.podss {
  position: absolute;
  text-align: start;
  left: 10px; /* Adjust the left value as needed */
  top: 50%; /* Adjust the top value as needed */
  transform: translateY(-50%);
}


.Faq-class{
  font-size: 20px;
  font-weight: bold;
}
/* Add these styles to your CSS file or style tag */

/* Style for the modal container */
/* Style for the modal container */

.openModalBtn {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: cornflowerblue;
  color: white;
  cursor: pointer;
}
.correct{
  padding-bottom: 20px;
}
.slick-slider .slick-track, .slick-slider .slick-list{
  
  display: flex;
  gap: 20px;

}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .slick-slider .slick-track, .slick-slider .slick-list{
 
  gap: 11px;

}
}
@media only screen and (min-width: 320px) and (max-width: 400px) {
  .Images-correct{
    height: 80px;

    
  }

}
@media only screen and (min-width: 400px) and (max-width: 600px) {
  .Images-correct{
    height: 100px;

    
  }

}
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .Images-correct{
    height: 140px;

    
  }

}

/* For medium screens (md) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .Images-correct{
    height: 150px;

  }
}

/* For large screens (lg) */
@media only screen and (min-width: 1200px) {
  .Images-correct{
    height: 200px;

  }
}
@media only screen and (min-width: 320px) and (max-width: 400px) {
  .Images-corrects{
    height: 129px;

    
  }

}
@media only screen and (min-width: 400px) and (max-width: 600px) {
  .Images-corrects{
    height: 135px;

    
  }

}
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .Images-corrects{
    height: 150px;

    
  }

}

/* For medium screens (md) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .Images-corrects{
    height: 200px;

  }
}

/* For large screens (lg) */
@media only screen and (min-width: 1200px) {
  .Images-corrects{
    height: 200px;

  }
}

.css-1g246y3-MuiCardContent-root:last-child{
  padding-bottom: 0;
}


/* ///////////////////////////// */

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .ImagesDiscovery{
    height: 180px;

    
  }

}
@media only screen and (min-width: 400px) and (max-width: 600px) {
  .ImagesDiscovery{
    height: 250px;

    
  }

}
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .ImagesDiscovery{
    height: 135px;

    
  }

}

/* For medium screens (md) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .ImagesDiscovery{
    height: 200px;

  }
}

/* For large screens (lg) */
@media only screen and (min-width: 1200px) {
  .ImagesDiscovery{
    height: 200px;

  }
}






/* background: #FFFCF9; */
  /* width: 60%; */
  /* padding: 60px; */
  /* margin: 20px auto; */
  /* border-radius: 50px; */




  .new-style{
    position: absolute;
    background-color: aliceblue;
    margin-top: 28px;
    right: 5%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 15px;
    z-index: 11;
  }
.hii{
  padding-bottom: 10px;
}
.react-multiple-carousel__arrow--left {
  margin-left: -5%;
}
.react-multiple-carousel__arrow--right {
  margin-right: -5%;
  
}
