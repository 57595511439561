/* Add these styles to your Modal.css */

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: transparent; /* Background blur effect */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0; /* Updated to center vertically */
  left: 0; /* Updated to center horizontally */
  z-index: 4;
  backdrop-filter: blur(8px); 
}

.modalContainer {
  width: 500px;
  height: fit-content;
  border-radius: 12px;
  background-color: #ffffffe9;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

/* ... (your existing styles) */
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .modalContainer {
   width: 310px;
  padding: 15px;

  }
}
.Image-size{
  width: 240px; height: 75px; 
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Image-size {
   width: 150px;
   height: 45px;
  }
}


.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}
.Input-fields{
    height: 34px;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding-left: 15px;
    width: 94%;
}

.New-register{
color: #FF1200;
cursor: pointer;
font-weight: bold;
}

.Input-fieldss{
    height: 38px;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding-left: 15px;
    width: 100%;
}
.Input-neww{
    height: 34px;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding-left: 15px;
    width: 95%;
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Input-neww{
    height: 34px;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding-left: 15px;
    width: 95%;
}
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .Input-neww{
    height: 32px;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding-left: 15px;
    width: 98.5%;
}
}