/* Login.css */
.login-container {
  position: relative;
  
  overflow: hidden;
}

.form-container {
  bottom: 56px;
  left: 0;
  width: 100%;
  padding: 7px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease-in-out;
}

.signup .form-container {
  transform: translateY(-100%);
}
